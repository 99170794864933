import store from '@/store'

const STRIPE_ENGINE = 'stripe'

export function isStripeActive() {
  const engine = store.getters['subscriber/getEngine']
  const isLoading = store.getters['subscriber/isLoading']
  const company = store.getters['session/company']
  if (!engine && !isLoading) {
    store.dispatch('setEngine', company.id)
  }

  return engine === STRIPE_ENGINE
}
