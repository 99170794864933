import Cookies from 'js-cookie'
import ENV from '../../enviroment.json'

// pseudo-random character function
const randomChars = () => (Math.random() + 1).toString(36).substring(2, 7)
const widgetModuleNameRandom = randomChars()

const appEnviroment = String(process.env.VUE_APP_ENVIROMENT).toLowerCase()
const baseApiUrl = ENV[appEnviroment]['BASE_API_URL']
const baseUrl = ENV[appEnviroment]['BASE_URL']
const isWidget = process.env.VUE_APP_IS_WIDGET === 'true'
const baseApiConfigUrl = getEnv('BASE_API_CONFIG_URL')
const widgetModuleName = ENV['WIDGET_MODULE_NAME'] || widgetModuleNameRandom

// Use the "en-US" format, as is the default formatfor displaying prices in Alegra.
const numberFormatterInstance = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

/**
 * Returns a tring with the number formated, with 2 digits
 * @param {Number} number to be formated
 */
const numberFormatter = number => numberFormatterInstance.format(number)

/**
 * Returns the value from enviroment.json or process.env, taking in account the appEnviroment of the user (local, testing or production), in this order:
 *  - Fisrt, try to get the ENV[appEnviroment][key]
 *  - Then if not found, try to get the ENV[key]
 *  - Lastly, if not found, try to get the process.env[key]
 * @param {String} key to look for
 * @param {String} env must be "local", "testing" or "production". Defaults to: `process.env.VUE_APP_ENVIROMENT`
 */
function getEnv(key, env = appEnviroment) {
  if (ENV[env]?.[key]) {
    return ENV[env]?.[key]
  } else if (ENV?.[key]) {
    return ENV?.[key]
  } else if (process.env[key]) {
    return process.env[key]
  }
  return null
}

/**
 * Returns wheater the app can be mounted, dependeing if the cookie of Alegra session is present.
 */
function canBeMounted() {
  const cookieTokenKey = getEnv('COOKIE_TOKEN') // ENV[appEnviroment][]

  if (appEnviroment === 'production' && !Cookies.get(cookieTokenKey)) return false

  return true
}

/**
 * Take only specified keys of the object passed as argument
 * @param {Object} obj to take keys from
 * @param {Array<String>} keys Array of Strings that contains the keys to get from the object
 */
function pick(obj, keys) {
  return Object.keys(obj)
    .filter(i => keys.includes(i))
    .reduce((acc, key) => {
      acc[key] = obj[key]
      return acc
    }, {})
}

/**
 * Returns a JSON object with the content of the common and specific translations of this project
 * @param {Array<string>} paths If specified, pick only these paths from the lang files
 */
function convertDictionaryToObject(paths = []) {
  const langKeys = ['en', 'es']
  const vk = ['AR', 'CL', 'CO', 'CR', 'DO', 'ES', 'KE', 'MX', 'NG', 'PA', 'PE', 'US', 'ZA']

  return {
    // Iterate over the langs
    ...langKeys.reduce((accLang, lang) => {
      let langCommon = require(`../languages/${lang}/common.js`).default
      if (paths && paths.length) {
        langCommon = pick(langCommon, paths)
      }

      return {
        // adds the previous obeject
        ...accLang,
        // create key-value pair of lang and common & locale
        [lang]: {
          // get the common file
          common: langCommon,
          // iterate over the versions keys
          locale: {
            ...vk.reduce((accVer, ver) => {
              let langVer = require(`../languages/${lang}/locale/${lang}_${ver}.js`).default
              if (paths && paths.length) {
                langVer = pick(langVer, paths)
              }

              return {
                // adds the previous obeject
                ...accVer,
                // create the key-value pair with the name of the file and its content
                [`${lang}_${ver}`]: langVer
              }
            }, {})
          }
        }
      }
    }, {})
  }
}

/**
 * Returns the token of the session of the user
 */
function getCookieToken() {
  const cookieTokenKey = getEnv('COOKIE_AUTH_TOKEN')
  return Cookies.get(cookieTokenKey) || localStorage.getItem(cookieTokenKey)
}

export {
  // constants
  appEnviroment,
  baseApiUrl,
  baseUrl,
  baseApiConfigUrl,
  isWidget,
  widgetModuleName,
  // methods
  numberFormatter,
  canBeMounted,
  pick,
  convertDictionaryToObject,
  getEnv,
  getCookieToken
}
