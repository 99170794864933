import Vue from 'vue'
import { convertDictionaryToObject } from '@/utils/appHelpers'
import router from './router'
import store from '../store'
// Get the app dictionary and convert it to oobject
export const appDictionary = convertDictionaryToObject()

export const initializeWC = () => {
  const bootFiles = require.context('../boot', false, /\.js$/) // https://webpack.js.org/guides/dependency-management/#requirecontext
  bootFiles.keys().forEach(modulePath => {
    const module = bootFiles(modulePath)
    module.default({ Vue, router, store }) // default export from module
  })
}
