// For a detailed explanation, see: https://smile.alegra.com/ui-alegra/#/sidebar
const menuToOverride = {}
// Example:
/* const menuToOverride = {
  // Possible id value:
  // "home", "fe", "incomes", "expenses", "contacts", "stock", "banks", "accounting", "payroll", "reports", "pos", "shop", "settings", "acrecer"
  id: "stock",
  submenu: [
    {
      id: "stock-price-list",
      order: 5,
      link: { name: "price-list" }, // Absolute path or router-links props (like: { name: "price-list" )
      title: { key: "priceLists", fallback: "Listas de precios" },
      quickAction: { name: "price-list-add" }, // Absolute path or router-links props
      quickActionTitle: "Nuevo"
      // otherRoutes: [{ name: "price-list-edit" }] // Array of router-link Objects
      // Other Options:
      // versions: ["COL", "ARG"],      // Only displayed on this countries
      // notVersions: [],               // Not displayed on this countries
      // checkForDistributor: true      // If true, shows this section only if user have a distributor associated
      // isIncludedFn: ({ user, company }) => {          // function that returns boolean, if it's true, this sections is showed.
      //   return true
      // }
    }
  ],
  otherRoutes: [] // Array of router-link Objects
} */

export { menuToOverride }
