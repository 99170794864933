// mutations
const mutations = {
  SET_HAPPY_WEEK_DISCOUNT: (state, { discount }) => {
    state.discount = discount
  },
  SET_HAPPY_WEEK_IS_ELIGIBLE: (state, { isEligible }) => {
    state.isEligible = isEligible
  },
  SET_HAPPY_WEEK_IS_ACTIVE: (state, { isActive }) => {
    state.isActive = isActive
  }
}

export default mutations
