// Actions should allways return a Promise
const actions = {
  submit({ commit }) {
    return new Promise(resolve => {
      commit('SET_SUBMIT_TRUE')
      resolve()
    })
  },
  resetSubmit({ commit }) {
    return new Promise(resolve => {
      commit('SET_SUBMIT_FALSE')
      resolve()
    })
  },
  setStep({ commit }, step) {
    return new Promise(resolve => {
      commit('SET_STEP', { step })
      resolve()
    })
  },
  setLoading({ commit }, loading = true) {
    return new Promise(resolve => {
      if (loading) {
        commit('SET_LOADING_TRUE')
      } else {
        commit('SET_LOADING_FALSE')
      }
      resolve()
    })
  },
  setStepLoading({ commit }, payload = { loading: false, text: '', enableTotalBar: true }) {
    return new Promise(resolve => {
      if (payload && payload.loading) {
        commit('SET_STEP_LOADING_TRUE', { text: payload.text })
        commit('payment/DISABLE_TOTAL_BAR', null, { root: true })
      } else {
        commit('SET_STEP_LOADING_FALSE')
        if (payload.enableTotalBar) commit('payment/ENABLE_TOTAL_BAR', null, { root: true })
      }
      resolve()
    })
  }
}

export default actions
