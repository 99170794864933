import Vue from 'vue'
import Vuex from 'vuex'
import { hasModulePlugin, registerModuleOncePlugin } from './utils'

// import example from './example/index'
import plan from './plan/index'
import payment from './payment/index'
import steps from './steps/index'
import webComponent from './webComponent/index'
import happyWeek from './happyWeek/index'
import subscriber from './subscriber/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [hasModulePlugin, registerModuleOncePlugin],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    plan,
    payment,
    steps,
    webComponent,
    happyWeek,
    subscriber
  }
})

export default store

export const userLoaded = () =>
  new Promise(resolve => {
    const unwatch = store.watch(
      state => state.session.user,
      user => {
        Vue.nextTick(() => {
          if (user && user.id) {
            resolve({ user, unwatch })
          }
        })
      },
      { immediate: true }
    )
  })
