// getters
const getters = {
  isSubmitted: state => state.submitted,
  isLoading: state => state.generalLoading,
  isStepLoading: state => state.stepLoading,
  loadingText: state => state.loadingText,
  getStep: state => state.step
}

export default getters
