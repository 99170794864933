import Membership from './Membership'
import StripeMembership from './StripeMembership'
import { isStripeActive } from '@/services/StripeServices'
import store from '@/store'
export default class AxiosService {
  static apiClient = Membership

  constructor() {
    const company = store.getters['session/company']
    const isLoading = store.getters['subscriber/isLoading']
    // Si la información de la compañía no está cargada, se espera a que esté cargada con el store.watch
    if (typeof AxiosService.instance === 'object') {
      return AxiosService.instance
    }

    if (!company) {
      const unwatch = store.watch(
        state => state.session.company,
        async newCompany => {
          if (newCompany != null) {
            if (!isLoading) {
              await store.dispatch('subscriber/setEngine', newCompany.id)
            }
            this.setApiClient()
            unwatch()
          }
        }
      )
    } else {
      if (!store.getters['subscriber/getEngine'] && !isLoading) {
        store.dispatch('subscriber/setEngine', company.id)
      }
      // Si la información de la compañía ya está cargada, se inicializa el servicio de memberships
      this.setApiClient()
    }
    AxiosService.instance = this
  }

  setApiClient() {
    if (isStripeActive()) {
      this.apiClient = StripeMembership
    } else {
      this.apiClient = Membership
    }
  }
}
