import { apiClient } from '@/services/index'

export default {
  getSuscription(idCompany, product) {
    return apiClient.get('/subscribers/' + idCompany + '/subscriptions/current?product=' + product)
  },
  getPreview(idCompany, product, idPlan, frequency) {
    return apiClient.get(
      `/subscribers/${idCompany}/subscription-preview?product=${product}&desiredPlan=${idPlan}&frequency=${frequency}`
    )
  },
  getAvailablePlans(idCompany, product, lang) {
    return apiClient.get(
      '/subscribers/' + idCompany + '/available-plans' + '?product=' + product + '&lang=' + lang
    )
  },
  getPlanDetail(idCompany, idPlan, lang, currency, product) {
    return apiClient.get(
      '/subscribers/' +
        idCompany +
        '/available-plan/' +
        idPlan +
        '?lang=' +
        lang +
        '&product=' +
        product
    )
  },
  getPaymentPlatforms(idCompany) {
    return apiClient.get('/subscribers/' + idCompany + '/payment/platforms')
  },
  getSavedPaymentMethods(idCompany) {
    return apiClient.get('/subscribers/' + idCompany + '/payment/methods')
  },
  getBillingInfo(idCompany) {
    return apiClient.get('/subscribers/' + idCompany + '/billing')
  },
  setBillingInfo(idCompany, data) {
    return apiClient.post('/subscribers/' + idCompany + '/billing', data)
  },
  subscribe(idCompany, data) {
    return apiClient.post('/subscribers/' + idCompany + '/subscribe', data)
  },
  changeDefaultPayment(idCompany, idLocal) {
    return apiClient.post('/subscribers/' + idCompany + '/payment/methods/change-default', {
      idLocal
    })
  },
  updatePaymentMethod(idCompany, idPaymentMethod, data) {
    return apiClient.put('/subscribers/' + idCompany + '/payment-methods/' + idPaymentMethod, data)
  }
}
