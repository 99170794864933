export default {
  general: {
    chooseAnOption: 'Selecciona una opción',
    saveMoney: 'Ahorra',
    save: 'guardar',
    cancel: 'cancelar',
    add: 'agregar',
    edit: 'editar',
    remove: 'eliminar',
    successUpdatingAlert: 'Actualizado exitosamente.',
    redFieldsAlertTitle: 'Revisa los campos marcados en rojo',
    redFieldsAlert:
      'Te recomendamos verificar la información de tu tarjeta para continuar con la compra de tu plan.',
    actualPlanAlert:
      'El plan que elegiste es tu plan actual y se renovará automáticamente al finalizar tu suscricpión',
    errorAlert:
      'Parece que ocurrio un error, lo estamos solucionando por favor intenta de nuevo más tarde o ponte en contacto con soporte',
    validation: {
      required: 'este campo es obligatorio',
      invalidCardNumber: 'Verifica que sea el número correcto',
      fourDigitsYear: 'este campo debe de contener 4 dígitos',
      pastYear: 'Debe ser posterior a la fecha actual',
      invalid_email: 'este correo electrónico es invalido',
      invalidCardLength: 'el número de tarjeta debe ser de 16 dígitos',
      invalidName: 'escribe un nombre válido',
      maxLengthName: 'este campo debe ser menor a {{qty}} caracteres',
      minLengthName: 'este campo debe ser mayor a {{qty}} caracteres',
      invalidMonth: 'este mes no existe',
      invalidCVC: 'el código de seguridad debe ser mayor a 2 dígitos',
      amexNotPermitted: 'no podemos procesar tu pago con American Express',
      expiredDate: 'Debe ser posterior a la fecha actual',
      onlyNumbers: 'este campo debe ser númerico'
    }
  },
  planPage: {
    actualPlan: 'Actualmente estás suscrito en el plan',
    subtitle: 'Elige uno de nuestros planes y sigue creciendo con Alegra',
    popular: 'popular',
    otherPlan: '¿Aún no encuentras el mejor plan para ti?',
    pos: '(*) El POS estará limitado solo por el valor de los ingresos mensuales',
    dontLikePlans: '¿No sabes cuál elegir?',
    askUs: 'Nosotros te asesoramos',
    contactSupport: 'Contactar a soporte',
    completePlans: 'planes completos',
    onlyInvoicingPlans: 'planes solo facturación',
    onlyInvoicing: 'solo facturación',
    choosePlan: 'elegir plan',
    disabledByUpgrade: {
      currentPlan: 'este es tu plan actual',
      lowerPlan: 'plan con menos capacidad que el actual',
      tooltip: 'Debes elegir un plan con una capacidad mayor al actual',
      income: {
        lowerPlan: 'plan con menos ingresos que el actual',
        tooltip: 'Debes elegir un plan con una capacidad de facturación mayor al actual'
      }
    },
    contactUs: 'contáctanos',
    month: 'mes',
    months: 'meses',
    happyWeekBanner: 'happyweek-202305-banner-es.jpg'
  },
  wizard: {
    header: {
      method: 'método de pago',
      summary: 'resumen',
      payment: 'pago'
    },
    footer: {
      securePaymentMethodMsg: 'Tu seguridad es nuestra prioridad, los datos son cifrados por',
      back: 'Volver'
    },
    paymentData: {
      header: {
        title: 'Tarjeta de crédito o débito',
        addNewCard:
          'Ingresa los datos de tu tarjeta de crédito a continuación para realizar el pago.',
        addOtherCard: 'Ingresa los datos de otra tarjeta si deseas crear un nuevo método de pago.'
      },
      fullName: 'Nombre del titular',
      cardNumber: 'Número de tarjeta',
      cardInfo: 'Información de la tarjeta',
      expDate: 'Fecha de vencimiento',
      securityCode: 'Cód. de seguridad',
      default: 'Marcar como método de pago favorito',
      cardNamePlaceholder: 'Escribe el nombre que aparece en la tarjeta',
      cardYearPlaceholder: 'aaaa',
      couponLabel: '¿Tienes un código promocional?',
      couponPlaceholder: 'Código promocional',
      couponApply: 'aplicar',
      couponApplied: 'aplicado',
      couponInvalidTitle: 'Código promocional inválido',
      couponInvalid: 'El código promocional no es válido'
    },
    bankAccountMethod: {
      bankAccountMethod: 'cuenta bancaria',
      bankEntity: 'entidad bancaria',
      accountType: 'tipo de cuenta',
      name: 'titular',
      identification: 'documento de identificación del titular',
      accountNumber: 'número de cuenta',
      accountTypesOptions: {
        current: 'cuenta corriente',
        savings: 'cuenta de ahorros',
        currentAbr: 'cta. corriente',
        savingsAbr: 'cta. ahorros'
      }
    },
    recurrentMsg: {
      part1: 'Al realizar tu pago aceptas nuestros ',
      part2:
        '. El cobro recurrente de tu plan se aplicará de forma automática a tu método de pago y puedes retirarlo en cualquier momento.',
      termsAndConditions: 'términos y condiciones',
      link: 'https://www.alegra.com/legal/terminos-y-condiciones/#medios'
    },
    paymentProcessing: 'procesando el pago...',
    paymentPlatforms: {
      modalSubtitle: 'selecciona el método de pago de tu preferencia',
      otherPaymentPlatforms: 'Otros medios de pago',
      DebitCard: {
        title: 'cuenta bancaria',
        description: 'el cobro se realizará entre 2 y 5 días hábiles'
      },
      CreditCard: { title: 'tarjeta de crédito / débito', description: ' ' }
    }
  },
  totalBar: {
    title: 'resumen de la suscripción',
    frequencyTitle: 'frecuencia de cobro',
    limitedTimeOffer: 'promoción por tiempo limitado',
    day: 'dia',
    days: 'dias',
    today: 'hoy',
    from: 'desde',
    until: 'hasta',
    summary: {
      subtotal: 'subtotal',
      descuento: 'descuento',
      impuestos: 'impuestos',
      continue: 'continuar',
      payNow: 'pagar ahora'
    }
  },
  paymentMethod: {
    paymentMethods: 'métodos de pago',
    card: 'tarjeta de crédito / débito'
  },
  billing: {
    subtitle: 'verifica los datos con los que se generará la factura por el pago de tu plan.',
    billingDetails: 'datos para tu factura',
    fullName: 'Razón social / Nombre Completo',
    identification: 'identificación',
    personalInfo: 'información personal',
    address: 'dirección',
    identificationType: 'tipo de identificación',
    identificationNumber: 'número de identificación',
    kindOfPerson: 'tipo de persona',
    taxLiability: 'responsabilidad tributaria',
    firstName: 'primer nombre',
    secondName: 'segundo nombre',
    lastName: 'apellidos',
    country: 'país',
    phone: 'teléfono',
    email: 'correo electrónico',
    kindOfPersonOptions: {
      legalPerson: 'persona jurídica',
      naturalPerson: 'persona natural'
    },
    identificationTypeOptions: {
      NIT: 'NIT - Número de identificación tributaria',
      CC: 'CC - Cédula de ciudadanía',
      DIE: 'DIE - Documento de identificación extranjero',
      PP: 'PP - Pasaporte',
      CE: 'CE - Cédula de extranjería',
      TE: 'TE - Tarjeta de extranjería',
      IT: 'TI - Tarjeta de identidad',
      RC: 'RC - Registro civil',
      NITOtherCountry: 'NIT de otro país - Identificación de otro país',
      NUIP: 'NUIP - Número único de identificación personal'
    },
    regimesOptions: {
      responsibleForVAT: 'Responsable de IVA',
      notResponsibleForVAT: 'No responsable de IVA',
      nationalConsumptionTax: 'Impuesto Nacional al Consumo - INC',
      notResponsibleForINC: 'No responsable de INC',
      responsibleForVATAndINC: 'Responsable de IVA e INC',
      specialRegime: 'Régimen especial'
    }
  },
  frequencyCharge: {
    anual: 'anual',
    biannual: 'semestral',
    mensual: 'mensual'
  },
  confirmationPage: {
    choosenPlan: 'plan elegido'
  },
  receiptPage: {
    status: '¡Pago exitoso!',
    invoiceNumber: 'factura #',
    // sendedTo: 'hemos enviado la factura de pago al correo',
    sendedTo:
      'Gracias por tomar la decisión de darle superpoderes a tu negocio. Recibirás tu factura muy pronto en el correo del usuario administrador.',
    goToSuscription: 'ver suscripción',
    thanks: '¡Gracias por tu compra!',
    makeUseOfAlegra: '¡Tu Plan {{plan}} ya está activo!'
  },
  planNames: {
    pyme: 'pyme',
    pro: 'pro',
    plus: 'plus',
    entrepreneur: 'emprendedor',
    emprendedor: 'emprendedor',
    corporativo: 'corporativo',
    free: 'gratis',
    gratis: 'gratis'
  },
  happyWeek: {
    title: 'Happy Week',
    duration: '2 meses'
  }
}
