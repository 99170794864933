// mutations
const mutations = {
  SET_SUBMIT_TRUE: state => {
    state.submitted = true
  },
  SET_SUBMIT_FALSE: state => {
    state.submitted = false
  },
  SET_LOADING_TRUE: state => {
    state.generalLoading = true
  },
  SET_LOADING_FALSE: state => {
    state.generalLoading = false
  },
  SET_STEP_LOADING_TRUE: (state, { text }) => {
    state.stepLoading = true
    state.loadingText = text
  },
  SET_STEP_LOADING_FALSE: state => {
    state.stepLoading = false
    state.loadingText = ''
  },
  SET_STEP: (state, { step }) => {
    state.step = step
  }
}

export default mutations
