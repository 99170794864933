import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'abstract',
  routes
})

router.beforeEach((to, from, next) => {
  if (!from.name && to.name != 'plan' && to.name != 'payment-form') next({ name: 'plan' })
  next()
})

export default router
