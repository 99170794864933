// mutations
const mutations = {
  SET_MEMBERSHIP_CLIENT: (state, { client }) => {
    state.membershipClient = client
  },
  SET_PAYMENT_METHOD: (state, { method }) => {
    state.paymentMethod = method
  },
  SET_PAYMENT_DATA: (state, { data }) => {
    let month = data.month?.length == 1 ? '0' + data.month : data.month
    let dataToSave
    if (month) {
      dataToSave = {
        ...data,
        expirationDate: data.year + '/' + month
      }
    } else {
      dataToSave = {
        ...data
      }
    }
    delete dataToSave.month
    delete dataToSave.year
    state.paymentData = dataToSave
  },
  SET_BANK_DATA: (state, { data }) => {
    state.bankData = data
  },
  RESET_PAYMENT_DATA: state => {
    state.paymentData = {
      payerName: '',
      accountNumber: '',
      accountType: '',
      expirationDate: '',
      securityCode: '',
      idLocal: null,
      default: false
    }
    state.bankData = {
      bankEntity: '',
      accountType: '',
      payerName: '',
      documentType: '',
      documentNumber: '',
      accountNumber: ''
    }
    state.paymentMethod = null
    state.error = null
  },
  SET_PAYMENT_ERROR: (state, { error }) => {
    state.error = error
  },
  CLEAR_PAYMENT_ERROR: state => {
    state.error = null
  },
  DISABLE_TOTAL_BAR: state => {
    state.totalBarDisabled = true
  },
  ENABLE_TOTAL_BAR: state => {
    state.totalBarDisabled = false
  },
  SET_NEW_TRANSACTION: (state, { data }) => {
    state.transactions.push({
      idTransaction: data.idTransaction,
      idReceipt: data.idReceipt,
      email: data.email
    })
  }
}

export default mutations
