import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const initialState = () => ({
  product: null,
  finishTextButton: 'Ver Suscripción',
  isStripeActive: undefined,
  upgrade: null,
  newCheckoutVersions: [
    'southAfrica',
    'nigeria',
    'kenya',
    'chile',
    'spain',
    'usa',
    'other',
    'republicaDominicana',
    'panama',
    'costaRica',
    'mexico',
    'peru',
    'argentina',
    'colombia'
  ]
})

const state = initialState()

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
