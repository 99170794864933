import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const initialState = () => ({
  plan: {},
  extras: [],
  discount: 0,
  numPayments: 12,
  productId: '',
  coupon: null
})

const state = initialState()

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
