import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const initialState = () => ({
  paymentData: {
    payerName: '',
    accountNumber: '',
    accountType: '',
    expirationDate: '',
    securityCode: '',
    idLocal: null,
    default: false
  },
  bankData: {
    bankEntity: '',
    accountType: '',
    payerName: '',
    documentType: '',
    documentNumber: '',
    accountNumber: ''
  },
  paymentMethod: null,
  error: null,
  totalBarDisabled: false,
  transactions: [],
  membershipClient: null
})

const state = initialState()

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
