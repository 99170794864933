// mutations
const mutations = {
  SET_SUBSCRIBER_ENGINE: (state, { engine }) => {
    state.engine = engine
  },
  SET_IS_LOADING: (state, { isLoading }) => {
    state.isLoading = isLoading
  }
}

export default mutations
