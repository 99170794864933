// Actions should allways return a Promise
const actions = {
  setProductId({ commit }, productId) {
    return new Promise(resolve => {
      commit('SET_PRODUCT_ID', { productId })
      resolve()
    })
  },
  setPlan({ commit }, plan) {
    return new Promise(resolve => {
      commit('SET_PLAN', { plan })
      resolve()
    })
  },
  resetPlan({ commit }) {
    return new Promise(resolve => {
      commit('RESET_PLAN')
      commit('RESET_EXTRAS')
      resolve()
    })
  },
  addExtra({ commit }, extra) {
    return new Promise(resolve => {
      commit('ADD_EXTRA', { extra })
      resolve()
    })
  },
  removeExtra({ commit }, extraId) {
    return new Promise(resolve => {
      commit('REMOVE_EXTRA', { extraId })
      resolve()
    })
  },
  disableExtra({ commit }, extraId) {
    return new Promise(resolve => {
      commit('DISABLE_EXTRA', { extraId })
      resolve()
    })
  },
  enableExtra({ commit }, extraId) {
    return new Promise(resolve => {
      commit('ENABLE_EXTRA', { extraId })
      resolve()
    })
  },
  resetExtras({ commit }) {
    return new Promise(resolve => {
      commit('RESET_EXTRAS')
      resolve()
    })
  },
  setDiscount({ commit }, amount) {
    return new Promise(resolve => {
      commit('SET_DISCOUNT', { amount })
      resolve()
    })
  },
  addDiscount({ commit }, amount) {
    return new Promise(resolve => {
      commit('ADD_DISCOUNT', { amount })
      resolve()
    })
  },
  setNumPayments({ commit }, amount) {
    return new Promise(resolve => {
      commit('SET_NUM_PAYMENTS', { amount })
      resolve()
    })
  },
  setCoupon({ commit }, coupon) {
    return new Promise(resolve => {
      commit('SET_COUPON', { coupon })
      resolve()
    })
  },
  clearCoupon({ commit }) {
    return new Promise(resolve => {
      commit('SET_COUPON', { coupon: null })
      resolve()
    })
  }
}

export default actions
