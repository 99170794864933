<template>
  <div id="web-component-root">
    <sm-layout-base
      :sidebar-default-opened="sidebarDefaultOpened"
      :sidebar-section-menu="menuToOverride"
      :app-dictionary="appDictionary"
      :loading-app-text="$transF('etc.loadingApp')"
      @user-loaded="userLoaded"
    >
      <div v-if="isLoading" class="container-loading">
        <sm-progress-circle size="medium" />
      </div>
      <router-view v-show="!isLoading" :key="$route.path" />
    </sm-layout-base>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import checkCookieMixin from '../mixins/checkCookie'
import { menuToOverride } from '../config'
import store from '../store'
import router from './router'
import { initializeWC, appDictionary } from './utils'
import { eventBus } from '@alegradev/smile-ui'
import { request } from '@alegradev/smile-ui-alegra'

initializeWC()

export default {
  name: 'Memberships',
  mixins: [checkCookieMixin],
  props: {
    product: {
      type: String,
      default: 'alegra-web'
    },
    idPlan: {
      type: [String, Number],
      default: null
    },
    finishTextButton: {
      type: String,
      default: 'Ver Suscripción'
    },
    paymentFrequency: {
      type: [String, Number],
      default: '12'
    },
    useStripeCheckout: {
      type: Boolean,
      default: false
    },
    upgradeFeature: {
      type: String,
      default: ''
    }
    // onBack: {
    //   type: Function,
    //   default: () => alert('BACK')
    // },
    // onError: {
    //   type: Function,
    //   default: () => alert('ERROR')
    // },
    // onComplete: {
    //   type: Function,
    //   default: () => alert('COMPLETE')
    // }
  },
  store,
  router,
  data() {
    return {
      sidebarDefaultOpened: menuToOverride.id,
      menuToOverride: menuToOverride,
      appDictionary: appDictionary
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'steps/isLoading'
    })
  },
  mounted() {
    let redirectUrls

    if (typeof this.redirectUrls === 'string' || this.redirectUrls instanceof String) {
      redirectUrls = JSON.parse(this.redirectUrls)
    } else {
      redirectUrls = this.redirectUrls
    }

    this.setRedirectUrls(redirectUrls)
    this.setProduct(this.product)
    this.setFinishTextButton(this.finishTextButton)
    this.setNumPayments(this.paymentFrequency)
    this.setStripeActive(this.useStripeCheckout)
    this.setUpgrade(this.upgradeFeature)

    if (this.idPlan) {
      if (router.name !== 'payment-form') {
        router.push({ name: 'payment-form', params: { idPlan: this.idPlan } })
      }
    } else {
      if (router.path !== '/plan') {
        router.push('/plan')
      }
    }
  },
  created() {
    eventBus.$on('on-back', payload => {
      this.$emit('on-back', payload)
    })

    eventBus.$on('on-error', payload => {
      this.$emit('on-error', payload)
    })

    eventBus.$on('on-complete', payload => {
      this.$emit('on-complete', payload)
    })

    this.defineXDataSource()
  },
  methods: {
    defineXDataSource() {
      const _AVAILABLE_PRODUCT_X_DATA_SOURCE_KEYS = {
        'alegra-web': 'alegra-accounting',
        'alegra-shop': 'alegra-shops',
        'alegra-ne': 'alegra-payroll',
        'alegra-pos': 'pos',
        'alegra-invoicing': 'alegra-only-invoices'
      }
      const _DEFAULT_PRODUCT_X_DATA_SOURCE = 'alegra-memberships'
      const xDataSource =
        _AVAILABLE_PRODUCT_X_DATA_SOURCE_KEYS[this.product] || _DEFAULT_PRODUCT_X_DATA_SOURCE
      request.defaults.headers['x-data-source'] = xDataSource
    },
    ...mapActions('webComponent', [
      'setRedirectUrls',
      'setProduct',
      'setFinishTextButton',
      'setStripeActive',
      'setUpgrade'
    ]),
    ...mapActions('plan', ['setNumPayments']),
    userLoaded() {
      // do something if needed, else, erase this.
    }
  }
}
</script>

<style lang="scss">
@import '~@alegradev/smile-ui-alegra/dist/smile-ui-alegra';
@import '../assets/styles/styles.scss';

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#web-component-root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

#sm-content-area {
  height: 100%;
  overflow: hidden;
}

.topnav-area,
.layout-section.sidebar {
  display: none;
}

.app {
  position: unset !important;
  display: block !important;
}

.layout {
  display: block !important;
}

#hubspot-messages-iframe-container {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
  * {
    display: none !important;
  }
  iframe {
    visibility: hidden !important;
  }
}

.btn-chat-container {
  display: none !important;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#app {
  background: #f9fafd;
  min-height: 100vh;
}
.container-loading {
  height: 90vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}
</style>
