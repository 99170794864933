// getters
const getters = {
  getRecurringInterval: state => {
    if (state.numPayments == 1) {
      return 'month'
    } else if (state.numPayments == 2) {
      return 'hw'
    } else {
      return 'year'
    }
  },
  getPlanName: state => state.plan.name,
  getPlanPrice: state => state.plan.price,
  getPlanCurrency: state => state.plan.currency,
  getIdPlan: state => state.plan.id,
  getCoupon: state => state.coupon,
  getDiscount: state => state.discount,
  getDiscountBySelectedFrequency: (state, getters) => {
    if (state.numPayments == 12) {
      let price = getters.getPlanPrice || 0
      return price * 12 * 0.1
    }
    return 0
  },
  getSelectedPlan: (state, getters) => {
    let planName = state.plan.name
    planName = planName.replace('Plan ', '')
    planName = planName.replace('Solo facturación ', '')
    let onlyInvoicing = state.plan.keyword.includes('only-invoicing')

    let color = getters.getPlanThemeColor(planName.toLowerCase())
    return {
      ...state.plan,
      name: planName,
      price: state.plan.price * state.numPayments,
      color,
      onlyInvoicing,
      productId: state.productId
    }
  },
  isAFreePlan: state => {
    const planPrice = state.plan.price
    const haveToPay = state.plan.pay
    return planPrice === 0 && !haveToPay
  },
  getAllItems: (state, getters) => {
    let items = []
    let planName = getters.getSelectedPlanNameKeyword(state.plan.name)
    let onlyInvoicing = state.plan.keyword.includes('only-invoicing')
    let isNePlan = state.plan.keyword.includes('ne')
    let color = getters.getPlanThemeColor(planName.toLowerCase())

    items.push({
      name: planName,
      price: state.plan.price * state.numPayments,
      color,
      onlyInvoicing,
      isNePlan
    })

    let extras = state.extras
    if (extras.length > 0) {
      extras.forEach(extra => {
        items.push({
          ...extra,
          price: extra.price * state.numPayments
        })
      })
    }

    return items
  },
  getExtras: state => state.extras,
  getBreakdowns: state => {
    let subtotal =
      (state.plan.price || 0) * (state.numPayments || 0) +
      (state.extras
        .filter(ex => !ex.disabled)
        .reduce((total, current) => total + current.price, 0) || 0) *
        (state.numPayments || 0)
    let impuestos =
      (state.plan.tax || 0) * (state.numPayments || 0) +
      (state.extras.filter(ex => !ex.disabled).reduce((total, current) => total + current.tax, 0) ||
        0) *
        (state.numPayments || 0)
    let descuento = state.discount
    // if (state.coupon) {
    //  descuento += state.coupon?.discount.total
    // }
    return {
      subtotal,
      impuestos,
      descuento
    }
  },
  getTotal: (state, getters) => {
    let breakdowns = getters.getBreakdowns
    if (!breakdowns) return 0
    else return breakdowns.subtotal + breakdowns.impuestos - breakdowns.descuento
  },
  getNumPayments: state => state.numPayments,
  getCurrencySymbolOptions: () => option => {
    const _AVAILABLE_SYMBOLS = {
      PEN: 'S/',
      EUR: '€',
      KES: 'KSh',
      ZAR: 'R'
    }
    const _DEFAULT_SYMBOL = '$'
    return _AVAILABLE_SYMBOLS[option] || _DEFAULT_SYMBOL
  },
  getPlanCurrencySymbol: (state, getters) => {
    return getters.getCurrencySymbolOptions(state.plan.currency)
  },
  getSelectedPlanNameKeyword: () => plan => {
    let planName = plan
    planName = planName.replace('Plan ', '')
    planName = planName.replace('Solo facturación ', '')
    planName = planName.replace(' Nómina Electrónica', '')
    return planName
  },
  getPlanThemeColor: () => planName => {
    const _AVAILABLE_COLORS = {
      pyme: '#bfcd31',
      standard: '#bfcd31',
      pro: '#00b19d',
      plus: '#4a90e2',
      emprendedor: '#8592ad',
      corporativo: '#00535e'
    }
    const _DEFAULT_COLOR = '#00b19d'
    return _AVAILABLE_COLORS[planName] || _DEFAULT_COLOR
  }
}

export default getters
