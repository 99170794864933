// Actions should allways return a Promise
const actions = {
  setMembershipClient({ commit }, client) {
    return new Promise(resolve => {
      commit('SET_MEMBERSHIP_CLIENT', { client })
      resolve()
    })
  },
  setPaymentMethod({ commit }, method) {
    return new Promise(resolve => {
      commit('SET_PAYMENT_METHOD', { method })
      resolve()
    })
  },
  setPaymentData({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_PAYMENT_DATA', { data })
      resolve()
    })
  },
  resetPaymentData({ commit }) {
    return new Promise(resolve => {
      commit('RESET_PAYMENT_DATA')
      resolve()
    })
  },
  setBankData({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_BANK_DATA', { data })
      resolve()
    })
  },
  setPaymentError({ commit }, error) {
    return new Promise(resolve => {
      commit('SET_PAYMENT_ERROR', { error })
      commit('DISABLE_TOTAL_BAR')
      resolve()
    })
  },
  clearPaymentError({ commit }) {
    return new Promise(resolve => {
      commit('CLEAR_PAYMENT_ERROR')
      commit('ENABLE_TOTAL_BAR')
      resolve()
    })
  },
  setTransactionData({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_NEW_TRANSACTION', { data })
      resolve()
    })
  }
}

export default actions
