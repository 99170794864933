// Actions should allways return a Promise
const actions = {
  setHappyWeekDiscount({ commit }, discount) {
    return new Promise(resolve => {
      commit('SET_HAPPY_WEEK_DISCOUNT', { discount })
      resolve()
    })
  },
  setHappyWeekIsEligible({ commit }, isEligible) {
    return new Promise(resolve => {
      commit('SET_HAPPY_WEEK_IS_ELIGIBLE', { isEligible })
      resolve()
    })
  }
}

export default actions
