import Admin from '@/api/AxiosService/Admin'
import AxiosService from '@/api/AxiosService/index'

// Actions should allways return a Promise
const actions = {
  async setEngine({ commit }, idCompany) {
    if (!idCompany) return
    commit('SET_IS_LOADING', { isLoading: true })
    try {
      const { data } = await Admin.getSubscriberEngine(idCompany)
      const engine = data?.engine
      commit('SET_SUBSCRIBER_ENGINE', { engine })
    } catch (error) {
      commit('SET_SUBSCRIBER_ENGINE', { engine: '' })
    } finally {
      const axiosServices = new AxiosService()
      axiosServices.setApiClient()
      commit('SET_IS_LOADING', { isLoading: false })
    }
  }
}

export default actions
