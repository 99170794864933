import '@alegradev/smile-ui-alegra/dist/smile-ui-alegra.css'
import smileUiAlegra from '@alegradev/smile-ui-alegra'

import ENVIROMENT from '../../enviroment.json'
const appEnviroment = String(process.env.VUE_APP_ENVIROMENT).toLowerCase()

const baseApiUrl = ENVIROMENT[appEnviroment]['BASE_API_URL']
const baseUrl = ENVIROMENT[appEnviroment]['BASE_URL']
const authHeaderTokenDev = process.env.VUE_APP_AUTH_HEADER_TOKEN_DEV
const dataSource = process.env.VUE_APP_DATA_SOURCE_HEADER || 'alegra-memberships'

export default ({ Vue, store, router }) => {
  Vue.use(smileUiAlegra, {
    store,
    router,
    BASE_API_URL: baseApiUrl,
    BASE_URL: baseUrl,
    AUTH_HEADER_TOKEN_DEV: authHeaderTokenDev,
    APP_ENVIROMENT: appEnviroment,
    HEADER_X_DATA_SOURCE: dataSource
  })
}
