// getters
const getters = {
  getProduct: state => state.product,
  getFinishTextButton: state => state.finishTextButton,
  isStripeActive: state => state.isStripeActive,
  getUpgrade: state => state.upgrade,
  getNewCheckoutVersions: state => state.newCheckoutVersions
}

export default getters
