// Actions should allways return a Promise
const actions = {
  setProduct({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_PRODUCT', { data })
      resolve()
    })
  },
  setStripeActive({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_STRIPE_ACTIVE', { data })
      resolve()
    })
  },
  setFinishTextButton({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_FINISH_TEXT_BUTTON', { data })
      resolve()
    })
  },
  setUpgrade({ commit }, data) {
    return new Promise(resolve => {
      commit('SET_UPGRADE', { data })
      resolve()
    })
  }
}

export default actions
