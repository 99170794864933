const routes = [
  {
    path: '/plan',
    name: 'plan',
    component: () => import(/* webpackChunkName: "plan" */ '../../views/Plans.vue')
  },
  {
    // https://hopper.alegra.com/membership/payment-checkout/idPlan/38
    // https://hopper.alegra.com/membership/choose-payment-method/idPlan/38/months/1
    // https://hopper.alegra.com/membership/view-membership-transaction/id/3188/monthsPayed/1
    path: '/membership/payment-checkout/idPlan/:idPlan',
    name: 'payment-form',
    component: () =>
      import(/* webpackChunkName: "payment-form" */ '../../views/PaymentWizard/PaymentForm.vue'),
    props: true
  },
  {
    path: '/membership/payment-confirmation/idPlan/:idPlan',
    name: 'confirmation',
    component: () =>
      import(/* webpackChunkName: "confirmation" */ '../../views/PaymentWizard/Confirmation.vue')
  },
  {
    path: '/membership/view-membership-transaction/status',
    name: 'receipt',
    component: () =>
      import(/* webpackChunkName: "receipt" */ '../../views/PaymentWizard/AfterPayment.vue'),
    props: true
  },
  {
    path: '/my-suscription',
    name: 'my-suscription',
    component: () =>
      import(/* webpackChunkName: "my-suscription" */ '../../views/MySuscription.vue'),
    beforeEnter(to, from, next) {
      window.location.replace('/plan/consumption')
      next(false)
    }
  }
]

export default routes
