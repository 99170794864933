// mutations
const mutations = {
  SET_PRODUCT_ID: (state, { productId }) => {
    state.productId = productId
  },
  SET_PLAN: (state, { plan }) => {
    state.plan = plan
  },
  RESET_PLAN: state => {
    ;(state.plan = {}), (state.discount = 0), (state.numPayments = 12)
  },
  ADD_EXTRA: (state, { extra }) => {
    state.extras.push(extra)
  },
  REMOVE_EXTRA: (state, { extraId }) => {
    const i = state.extras.findIndex(extra => extra.id === extraId)
    if (i !== -1) state.extras.splice(i, 1)
  },
  DISABLE_EXTRA: (state, { extraId }) => {
    const i = state.extras.findIndex(extra => extra.id === extraId)
    state.extras[i].disabled = true
  },
  ENABLE_EXTRA: (state, { extraId }) => {
    const i = state.extras.findIndex(extra => extra.id === extraId)
    state.extras[i].disabled = false
  },
  RESET_EXTRAS: state => {
    state.extras = []
  },
  SET_DISCOUNT: (state, { amount }) => {
    state.discount = amount
  },
  ADD_DISCOUNT: (state, { amount }) => {
    state.discount += amount
  },
  SET_NUM_PAYMENTS: (state, { amount }) => {
    state.numPayments = amount
  },
  SET_COUPON: (state, { coupon }) => {
    state.coupon = coupon
  }
}

export default mutations
