// mutations
const mutations = {
  SET_PRODUCT: (state, { data }) => {
    state.product = data
  },
  SET_FINISH_TEXT_BUTTON: (state, { data }) => {
    state.finishTextButton = data
  },
  SET_STRIPE_ACTIVE: (state, { data }) => {
    state.isStripeActive = data
  },
  SET_UPGRADE: (state, { data }) => {
    state.upgrade = data
  }
}

export default mutations
